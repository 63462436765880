import "../styles/globals.css";
import "../components/Chart/ChartFilter/ChartFilter.css";
import "../components/Chart/ChartLegend/ChartLegend.css";
import "../components/Chart/ChartModal/ChartModal.css";
import "../components/Chart/ChartSideBar/ChartSideBar.css";
import "../components/Chart/ChartSideBar/ChartSideBarItem.css";
import "../components/Chart/ChartTitleBar/ChartTitleBar.css";
import "../components/Chart/ChartVisual/Callout/Callout.css";
import "../components/Chart/ChartVisual/ChartAreaClosedVisual/ChartAreaClosedVisual.css";
import "../components/Chart/FilterToggle/FilterToggle.css";
import "../components/Chart/Table/Table.css";
import "../components/Input/index.css";
import "../components/ToolTip/ToolTip.css";
import "components/ImageShare/ImageShare.css";
import "components/Typography/Typography.css";
import "components/Chart/ChartFilter/ChartFilter.css";
import "components/Chart/Chart/Chart.css";
import "components/Chart/ChartButton/ChartButton.css";
import "components/Legal/legal.css";
import "../whyDidYouRender";

import type { AppProps } from "next/app";
import type { NextPage } from "next";
import React, { ReactElement, ReactNode } from "react";
import { GlobalStyle } from "../styles/App.styles";
import { HelmetProvider } from "react-helmet-async";
import { AppContextProvider } from "../src/context";
import HeaderIndex from "../components/Header/IndexHeader";
import NoScript from "components/NoScript/NoScript";
import HeadSection from "../components/Header/HeadSection";
import HelloMoonEventManager from "components/Script/UserScript";
import Script from "next/script";

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    dataLayer: Record<string, any>;
  }
}

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export const PUBLIC_KEY_LOCALSTORAGE_KEY = "pubk" as const;

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  return getLayout(
    <React.StrictMode>
      <HelmetProvider>
        <HeaderIndex />
        <HelloMoonEventManager />
        <NoScript />
        <Script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
        />
        <AppContextProvider>
          <GlobalStyle />
          <HeadSection />
          <Component {...pageProps} />
        </AppContextProvider>
      </HelmetProvider>
    </React.StrictMode>,
  );
}

export default MyApp;
